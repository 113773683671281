<template>
  <v-dialog :value="true" persistent max-width="800px">
    <v-card>
      <v-card-title class="primary white--text">
        <span class="text-h5">{{ $t('uploadAttachments') }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <validation-observer ref="observer" v-slot="{ passes }">
            <v-form @submit.prevent="passes(submitForm)">
              <v-row>
                <v-col cols="12">
                  <validation-provider v-slot="{ errors }" name="resumo">
                    <v-text-field
                      v-model="form.resumo"
                      :label="$t('fields.resumo')"
                      :prepend-icon="icons.mdiTextSearch"
                      :error-messages="errors"
                      outlined
                      counter="80"
                      maxlength="80"
                      dense
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12">
                  <validation-provider v-slot="{ errors }" name="file" rules="required">
                    <v-file-input
                      v-model="form.file"
                      :label="`${$t('fields.file')} *`"
                      :error-messages="errors"
                      dense
                      accept="image/*,.pdf"
                      show-size
                      counter
                    ></v-file-input>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-form>
          </validation-observer>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="setAttachmentsDialog(false)">{{ $t('aClose') }}</v-btn>
        <v-btn text color="primary" @click="submitForm">{{ $t('aSend') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { mdiTextSearch } from '@mdi/js';

export default {
  emits: ['reload-list'],
  props: {
    bostamp: {
      type: String,
      required: true,
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    form: {
      resumo: '',
      file: null,
    },
    icons: { mdiTextSearch },
  }),
  computed: {
    ...mapGetters('loadOrders', ['attachmentsErrors']),
  },
  methods: {
    ...mapMutations(['setLoading']),
    ...mapMutations('loadOrders', ['setAttachmentsDialog']),
    ...mapActions('loadOrders', ['createAttachment', 'showAttachments']),
    async submitForm() {
      this.setLoading(true);

      await this.createAttachment({ ...this.form, bostamp: this.bostamp });

      await this.showAttachments(this.bostamp);

      this.$emit('reload-list');

      this.setLoading(false);
    },
  },
  watch: {
    attachmentsErrors() {
      this.$refs.observer.setErrors(this.attachmentsErrors);
    },
  },
};
</script>
