<template>
  <v-row>
    <v-col
      cols="1"
      v-for="item in attachments[bostamp]" :key="item.anexosstamp"
    >
      <v-card>
        <v-card-text class="cursor-pointer">
          <v-img
            v-if="item.fext !== 'pdf'"
            :src="`${sharePath}${item.fullname}`"
            aspect-ratio="0.9"
            @click="openImage(item)"
          />
          <v-img v-else @click="openPdf(item)" src="@/assets/pdf_icon.svg" aspect-ratio="1"/>
          <div class="text-center mt-5">{{ item.resumo }}</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="error"
            @click="deleteDialog = true; destroyAnexosstamp = item.anexosstamp"
          >
            {{ $t('aDelete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <wot-confirm v-if="deleteDialog" :remove="true" @confirm-action="submitDelete">
      {{ $t('wot.delete') }}
    </wot-confirm>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { mdiOpenInNew, mdiDeleteOutline } from '@mdi/js';

export default {
  components: {
    WotConfirm: () => import(/* webpackChunkName: "wot" */ '@/components/wot/WotConfirm.vue'),
  },
  emits: ['open-pdf', 'reload-list'],
  props: {
    bostamp: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    deleteDialog: false,
    destroyAnexosstamp: null,
    icons: { mdiOpenInNew, mdiDeleteOutline },
  }),
  computed: {
    ...mapGetters('loadOrders', ['attachments']),
    sharePath() {
      return `${process.env.VUE_APP_BACKEND_URL}PHC/`;
    },
  },
  methods: {
    ...mapActions('loadOrders', ['showAttachments', 'destroyAttachment']),
    ...mapMutations(['setLoading']),
    openPdf({ resumo, fullname }) {
      const fileName = `${this.sharePath}${fullname}`;

      this.$emit('open-pdf', { fileName, title: resumo });
    },
    openImage({ fullname }) {
      const fileName = `${this.sharePath}${fullname}`;

      window.open(fileName);
    },
    async submitDelete(confirm) {
      if (confirm) {
        this.setLoading(true);

        await this.destroyAttachment(this.destroyAnexosstamp);

        await this.showAttachments(this.bostamp);

        this.$emit('reload-list');

        this.setLoading(false);
      }

      this.deleteDialog = false;
    },
  },
  async mounted() {
    this.setLoading(true);

    await this.showAttachments(this.bostamp);

    this.setLoading(false);
  },
};
</script>

<style scoped>
.v-card {
  height: 100%;
}
</style>
